import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import arrow from '../img/arrow-right.svg'

const StyledPager = styled.div`
  pointer-events: none;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  .button {
    pointer-events: all;
    display: block;
    font-size: 0;

    width: 50px;
    height: 50px;

    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-position: center;
  }

  .button:first-child {
    transform: scaleX(-1);
  }
`

export default class Pager extends Component {
  render() {
    const { prevLink, nextLink } = this.props
    return (
      <StyledPager>
        {prevLink && (
          <Link className="button" to={prevLink}>
            vorige
          </Link>
        )}
        {nextLink && (
          <Link className="button" to={nextLink}>
            volgende
          </Link>
        )}
      </StyledPager>
    )
  }
}
