import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import styled, { css } from 'styled-components'
import { media } from '../settings/styles'
import FadeIn from '../components/FadeIn'
import { Helmet } from 'react-helmet'
import Pager from '../components/Pager'

import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' //

const Grid = styled.div`
  margin-bottom: 5rem;
  > *:nth-child(n + 2) {
    margin-top: 3rem;

    ${media.tablet`${css`
      margin-top: 2rem;
    `}`}
    ${media.phone`${css`
      margin-top: 1rem;
    `}`}
  }
`

export default class Project extends Component {
  constructor(props) {
    super(props)
    this.fullSizeImages = this.props.data.nodeProject.relationships.field_images.map(
      image => {
        return image.localFile.childImageSharp.large.src
      }
    )
  }

  state = { lightboxIsOpen: false, photoIndex: 0 }

  render() {
    const {
      data: { nextProject, prevProject, nodeProject: data },
    } = this.props

    const {
      title,
      field_description: description,
      relationships: {
        field_image: {
          localFile: {
            childImageSharp: { ogImage },
          },
        },
        field_images: rel_images,
      },
      field_images,
    } = data

    const images = rel_images.map((img, index) => {
      img.alt = field_images[index].alt
      return img
    })

    const { lightboxIsOpen, photoIndex } = this.state

    const prevLink = prevProject ? prevProject.path.alias : null
    const nextLink = nextProject ? nextProject.path.alias : null

    const { fullSizeImages } = this

    return (
      <Layout location={this.props.location}>
        <Helmet>
          <title>{title} | Wow my day</title>
          <meta name="description" content={description} />
          <meta name="og:image" content={ogImage.src} />
        </Helmet>
        <FadeIn>
          <PageTitle>{title}</PageTitle>
          <Grid className="l-constrained-narrow">
            {images &&
              images.map((image, index) => {
                return (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.setState({
                        lightboxIsOpen: true,
                        photoIndex: index,
                      })
                    }}
                    key={image.id}
                  >
                    <Img
                      alt={image.alt}
                      fluid={image.localFile.childImageSharp.fluid}
                    />
                  </div>
                )
              })}
          </Grid>
          <Pager prevLink={prevLink} nextLink={nextLink} />
          {lightboxIsOpen && (
            <Lightbox
              mainSrc={fullSizeImages[photoIndex]}
              nextSrc={fullSizeImages[(photoIndex + 1) % fullSizeImages.length]}
              prevSrc={
                fullSizeImages[
                  (photoIndex + fullSizeImages.length - 1) %
                    fullSizeImages.length
                ]
              }
              onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (photoIndex + fullSizeImages.length - 1) %
                    fullSizeImages.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % fullSizeImages.length,
                })
              }
            />
          )}
        </FadeIn>
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!, $nextAlias: String, $prevAlias: String) {
    nextProject: nodeProject(path: { alias: { eq: $nextAlias } }) {
      path {
        alias
      }
    }

    prevProject: nodeProject(path: { alias: { eq: $prevAlias } }) {
      path {
        alias
      }
    }

    nodeProject(path: { alias: { eq: $slug } }) {
      title
      path {
        alias
      }
      field_description
      field_images {
        alt
      }
      relationships {
        field_image {
          localFile {
            childImageSharp {
              ogImage: resize(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        field_images {
          id
          localFile {
            childImageSharp {
              large: fluid(maxWidth: 1500) {
                src
              }

              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
