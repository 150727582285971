import styled, { css } from 'styled-components'

import { rhythm } from '../settings/styles'

const PageTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  line-height: 1.2;
  text-align: center;
  margin: ${rhythm()} auto;
  max-width: 10em;
  color: #d1898f;

${props =>
  props.full &&
  css`
    max-width: none;
    margin-bottom: ${rhythm(2)};
  `}

  }
`

export default PageTitle
